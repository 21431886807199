import React from 'react'
import styled, { css } from 'styled-components'
import media from '../styles/media'

const Title = styled.h1`
  font-size: 2rem;
  font-size: ${props => props.medium && '1.8rem'};
  font-size: ${props => props.small && '1.6rem'};
  font-weight: 800;
  margin: 0 0 1.5rem 0;
  margin: ${props => (props.small ? '1rem 0 3rem 0' : '0 0 1.5rem 0')};
  line-height: 1.2;

  ${media.tablet`
    font-size: 2.4rem;
    font-size: ${props => props.medium && '2.2rem'};
    font-size: ${props => props.small && '2rem'};
    margin: 0 0 3rem 0;
  `};

  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  ${props =>
    props.withUnderline &&
    css`
      &:after {
        content: '';
        background: var(--gray-1);
        display: block;
        height: 6px;
        margin: 1.5rem 0;
        width: 80px;

        ${media.tablet`
          height: 8px;
          margin: 2rem 0;
          width: 80px;
        `};
      }
    `};
`

const PageTitle = props => {
  return (
    <Title
      withUnderline={props.withUnderline}
      small={props.small}
      medium={props.medium}
    >
      {props.children}
    </Title>
  )
}

export default PageTitle
